import React from "react";
import {Form} from "react-bootstrap";

export const ArcGisOptions = ({handleCheckBoxCheck, items}) => {

    return (
        <fieldset>
            {items.map(item => (
                <div key={item.id}>
                    <img
                        src={`images/${item.id === 'outage' ? 'outages.png' : 'new-sites.png'}`}
                        alt={item.id}
                        className="d-inline-block me-2"
                    />
                    <Form.Check className="d-inline-block">
                        <Form.Check.Input
                            id={item.id}
                            name="featureType"
                            type="checkbox"
                            defaultChecked={false}
                            onChange={handleCheckBoxCheck}
                        />
                        <Form.Check.Label>{item.title}</Form.Check.Label>
                    </Form.Check>
                </div>
            ))}
        </fieldset>
    );
};
