import React from "react";
import { Form } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";

export const CategoryOptions = ({
  type,
  showColors,
  isChecked = false,
  handleCheckBoxCheck,
}) => {
  const { allCategories } = useAppContext();
  const isCheckBox = type === "checkbox";

  return (
    allCategories && (
      <fieldset>
        {allCategories.map((category, index) => (
          <div key={index}>
            {showColors && (
              <img
                src={`images/${category.pinIcon}`}
                alt={category.name}
                className="d-inline-block me-2"
              />
            )}
            <Form.Check className="d-inline-block">
              <Form.Check.Input
                id={category.id}
                name={isCheckBox ? "categories" : "category"}
                type={type}
                className={isCheckBox ? "" : "custom-radio-select"}
                defaultChecked={isChecked}
                onChange={(e) => {
                  if (isCheckBox) handleCheckBoxCheck(e);
                }}
              />
              <Form.Check.Label className="one-body-medium">{category.name}</Form.Check.Label>
            </Form.Check>
          </div>
        ))}
      </fieldset>
    )
  );
};
