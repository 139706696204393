import {useEffect, useRef, useState} from "react";
import { Form, Card, Col, InputGroup } from "react-bootstrap";
import validator from "validator/es";
import { Errors } from "../Errors/Errors";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import { Autocomplete } from "@react-google-maps/api";
import { useAppContext } from "../../context/appContext";

export const StepOne = ({ nextStep }) => {
  const { setLatLng } = useAppContext();
  const [errors, setErrors] = useState([]);
  const addressInputRef = useRef();
  const { formData, setFormData } = useAppContext()

  useEffect(() => {
    if(errors?.length) {
      setTimeout(()=>setErrors([]),3000);
    }
  }, [errors]);


  const handlePlaceChanged = () => {
    const place = addressInputRef.current.getPlace();
    if (place) {
      const addressData = {
        postCode:
          place?.address_components?.find(
            (entry) => entry.types?.[0] === "postal_code"
          )?.long_name || "",
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLatLng({ lat: addressData.lat, lng: addressData.lng });
      setFormData({ ...formData, ...addressData });
    }
  };

  const validate = (values) => {
    let _errors = [];
    if (values.lat === "" || values.lng === ""){
      _errors.push(Errors.AddressRequired)
    }
    if (validator.isEmpty(values.email)) {
      _errors.push(Errors.EmailRequired);
    }
    if (!validator.isEmail(values.email)) {
      _errors.push(Errors.EmailIncorrectFormat);
    }
    if(validator.isEmpty(values.phoneNumber)){
      _errors.push(Errors.PhoneNumberRequired)
    }
    if(!validator.isMobilePhone(values.phoneNumber, "en-NZ")){
      _errors.push(Errors.PhoneNumberIncorrectFormat)
    }
    setErrors(_errors);
    return _errors;
  };

  const submitFormData = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      email: e.target.email.value,
      phoneNumber: e.target.phoneNumber.value.replaceAll(' ',''),
    };
    if (!validate(data).length) {
      setFormData(data);
      nextStep();
    }
  };

  const resetForm = (e) => {
    setFormData({...formData, phoneNumber: ""})
    for (let i = 0; i < e.target.form.length; i++) {
      switch (e.target.form[i].type.toLowerCase()) {
        case "text":
        case "password":
        case "textarea":
        case "hidden":
          e.target.form[i].value = "";
          formData[e.target.form[i].name] = "";
          break;
        case "radio":
        case "checkbox":
          e.target.form[i].checked = false;
          break;
        case "select-one":
        case "select-multi":
          e.target.form[i].selectedIndex = -1;
          break;
        default:
          break;
      }
    }
  };

  return (
    <Col id="formStepOne">
      <Card>
        <Card.Body>
          <h2 className="one-heading-small">Where shall we check?</h2>
          <Form id="formStepOneForm" onSubmit={submitFormData}>
            <Form.Group className="my-4">
              <Form.Label className="one-label-large">Address</Form.Label>
              <InputGroup className="standaloneSearchBox">
                <Autocomplete
                  ref={addressInputRef}
                  onLoad={(ref) => (addressInputRef.current = ref)}
                  onPlaceChanged={handlePlaceChanged}
                  options={{
                    componentRestrictions: { country: "nz" },
                  }}
                >
                  <input
                    name="address"
                    id="address"
                    type="text"
                    className="form-control"
                    placeholder="Start typing an address"
                  />
                </Autocomplete>
                {errors?.includes(Errors.AddressRequired) && (
                    <Form.Text className="error">
                      This is not a valid address
                    </Form.Text>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group className="my-4">
              <Form.Label className="one-label-large">Email</Form.Label>
              <Form.Control
                name="email"
                defaultValue={formData.email}
                type="text"
                placeholder="Enter email address"
              />
              {( errors?.includes(Errors.EmailRequired) || errors?.includes(Errors.EmailIncorrectFormat) ) && (
                <Form.Text className="error">
                  Email is required and must be in a correct format
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="my-4">
              <Form.Label className="one-label-large">Phone Number</Form.Label>
              <PhoneInput
                name="phoneNumber"
                className={"form-control"}
                country="NZ"
                placeholder="Enter mobile number"
                value={formData.phoneNumber}
                onChange={(newValue) =>
                  setFormData({ ...formData, phoneNumber: newValue })
                }
              />
              {( errors?.includes(Errors.PhoneNumberRequired) || errors?.includes(Errors.PhoneNumberIncorrectFormat) ) && (
                  <Form.Text className="error">
                    A valid New Zealand phone number is required.
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex flex-row gap-2">
            <button
              variant="light"
              form="formStepOneForm"
              onClick={resetForm}
              type="reset"
            >
              Reset
            </button>
            <button type="submit">
              Next
            </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
};
