import styles from "./Banner.module.css";

export const Banner = () => {
    return (
        <div className={styles.introduction}>
            <h2 className="one-display-medium mt-5 mb-4 text-left">
                We want all of Aotearoa to feel connected.
            </h2>
            <p className="one-body-large my-2">
                That&apos;s why we spend over $100,000,000 upgrading more than 300 sites each year. But there&apos;s nothing quite like hearing real feedback, so we&apos;re asking kiwis to tell us exactly where they&apos;re experiencing dropped calls, slow speeds or simply don&apos;t have connectivity at all.
            </p>
            <h3 className="one-display-small mt-5 mb-4">Introducing, One Pin!</h3>
            <p className="one-body-large my-2">
                Drop a pin and help us create a more connected Aotearoa.
            </p>
        </div>
    );
};