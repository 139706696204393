import axios from "axios";
import {getOutageByCoord} from "./ArcGis/useArcGisData";

export const Markers = [{ lat: -36.882161, lng: 174.804388 }];

export async function postReport({
  address,
  postCode,
  lat,
  lng,
  categoryIdToReport,
  email,
  phoneNumber,
  description,
  userMovedPin,
    visible
}) {
  // 1. insert user and get the reference
  const user = await postContactInfo(email, address, phoneNumber);

  // 2. insert pin
  if (user?.length) {
    await axios.post(
      "/api/pins?columns=contact_id,latitude,longitude,is_visible,zoom,category_ids,postal_code,userMovedPin,description",
      {
        contact_id: user[0].id,
        latitude: lat,
        longitude: lng,
        zoom: 12,
        is_visible: visible,
        category_ids: [parseInt(categoryIdToReport)],
        postal_code: postCode,
        userMovedPin: userMovedPin,
        description,
      }
    );
  }

  // TODO: testing, try to fetch some polygons with the lat, lng.
  //   await get4gCoverageForCoordinate({lat, lng});
}

export async function getMarkersByPostalCodes(data) {
  // format array into postgrest query
  const postalCodes = JSON.stringify(data)
    .replace(/^\[(.+)\]$/, "($1)")
    .replace(/"/g, "");
  return await axios(`/api/pins?postal_code=in.${postalCodes}`).then(
    (response) => response.data
  );
}

//FIXME @jsanc623 this returns 404. So, I have to remove all the related logic. From @KalvinWei
export async function getMarkersByPostCode(postCode) {
    return await axios(`/api/rpc/get_pins?c_post_code=${postCode}`).then((response) => response.data)
}

export async function getAllPinsInDB() {
  return await axios("/api/pins").then((response) => response.data);
}

export async function postContactInfo(email, address, phone_number) {
  return await axios
    .post("/api/rpc/insert_contact_info", { email, address, phone_number })
    .then((response) => response.data);
}
