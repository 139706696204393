import Accordion from "react-bootstrap/Accordion";
import styles from "./FurtherInformation.module.css";
import classNames from "classnames";

const content = [
  {
    question: "Terms & Conditions",
    answer: (
      <>
        <p>Terms and Conditions for One Pin.</p>
        <p>One Pin terms apply to any user of the website who drops a pin.</p>
        <h3>1.0 What information does One NZ collect?</h3>
        <p>
          1.1 Each time a user drops a pin, we collect the geographical
          identifier (the pin), your name, email address and mobile number.
        </p>
        <p>
          1.2 We may use the pin data to identify One NZ customers in
          conjunction with other products and services offered by One NZ and
          reward them as a unique user.
        </p>
        <h3>2.0 How does One NZ use your information?</h3>
        <p>
          2.1 The Pin: Each pin is collected and provided to our One NZ Network
          team who use the data to influence and inform their road map planning
          for network infrastructure.
        </p>
        <p>
          2.2 Name, email address and mobile number: This data is used to
          contact the user when the geographical area they have dropped a pin
          in, is scheduled for an update.
        </p>
        <h3>3.0 Sharing your information</h3>
        <p>
          This data will not be used in any other way nor will be used for third
          party use.
        </p>
        <h3>4.0 Protecting your personal information</h3>
        <p>
          Please review our&nbsp;
          <a href="https://www.one.nz/legal/policy/privacy/">privacy policy</a>.
        </p>
      </>
    ),
  },
];
export const FurtherInformation = () => {
  const QnAs = () => {
    return content.map((x, index) => {
      return (
        <div key={index}>
          <Accordion.Item eventKey={index}>
            <Accordion.Header className={classNames(styles.header)}>
              {x.question}
            </Accordion.Header>
            <Accordion.Body className={classNames(styles.body)}>
              {x.answer}
            </Accordion.Body>
          </Accordion.Item>
        </div>
      );
    });
  };

  return (
    <section id="further-information" className={`${styles.container}`}>
      <div className={styles.faqsWrapper}>
        <h3 className={classNames("one-display-small")}>Further information</h3>
        <Accordion flush>
          <QnAs />
        </Accordion>
      </div>
    </section>
  );
};
