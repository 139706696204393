import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LoadScript } from "@react-google-maps/api";

// Components
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Banner } from "./components/Banner/Banner";
import { Map } from "./components/Map/Map";
import { Form } from "./components/Form/Form";
import { Steps } from "./components/Steps/Steps";
import { FAQ } from "./components/FAQ/FAQ";

// Context
import AppContextProvider from "./context/appContext";
import { FurtherInformation } from "./components/FurtherInformation/FurtherInformation";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

function App() {
  const MapAndFormRow = (
    <Row className="justify-content-center">
      <Row className="map-form-wrapper">
        {/* map wrapper */}
        <Col
          xs={{ span: 12, order: 3 }}
          lg={{ span: 7, order: 1 }}
          className="mb-7 nopadding map-wrapper"
        >
          <Map
            GoogleMapsApiKey={
              REACT_APP_GOOGLE_MAPS_API_KEY
                ? REACT_APP_GOOGLE_MAPS_API_KEY
                : "AIzaSyACOerEeuppkKJylXOCTEKTuhxC6k488No"
            }
            Center={{ lat: -41.2974105, lng: 173.1696573 }}
            Zoom={6}
            mapContainerStyle={{
              width: "100%",
              height: "600px",
            }}
          />
        </Col>
        {/* form wrapper */}
        <Col
          xs={{ span: 12, order: 1 }}
          lg={{ span: 5, order: 2 }}
          className="lg-mb-5 nopadding overflow-hidden"
        >
          <Routes>
            <Route path="/" element={<Form startingStep={1} />} key="1" />
            <Route
              path="/drop-pins"
              element={<Form startingStep={2} />}
              key="2"
            />
            <Route
              path="/show-improvements"
              element={<Form startingStep={3} />}
              key="3"
            />
            <Route path="*" element={<Form startingStep={1} />} key="4" />
            <Route render={() => <h1>Page not found</h1>} />
          </Routes>
        </Col>
      </Row>
    </Row>
  );

  return (
    <MemoryRouter>
      <AppContextProvider>
        <Container fluid className="App">
          <Row className="d-flex justify-content-center text-center">
            <Col>
              <Header></Header>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col>
              <Banner></Banner>
            </Col>
          </Row>

          <Row className="justify-content-center pt-5 pb-3 py-lg-0 mt-lg-5 mx-1 lg-mx-5">
            <Steps />
          </Row>

          <LoadScript
            googleMapsApiKey="AIzaSyACOerEeuppkKJylXOCTEKTuhxC6k488No"
            libraries={["places"]}
          >
            {MapAndFormRow}
          </LoadScript>

          <Row className="justify-content-center my-10 px-4 lg-px-5">
            <Col className="col-md-12 col-12 nopadding">
              <FAQ></FAQ>
            </Col>
          </Row>

          <Row className="justify-content-center my-10 px-4 lg-px-5">
            <Col className="col-md-12 col-12 nopadding">
              <FurtherInformation />
            </Col>
          </Row>

          <Row className="d-flex justify-content-center text-center">
            <Col>
              <Footer></Footer>
            </Col>
          </Row>
        </Container>
      </AppContextProvider>
    </MemoryRouter>
  );
}

export default App;
