import axios from "axios";
import moment from "moment";

const ArcGisTokenProxyUrl =
  "https://australia-southeast1-pin-drop-349003.cloudfunctions.net/arcgis-token-proxy";

export default async function getToken() {
  try {
    const res = await axios.get(ArcGisTokenProxyUrl);
    // res.data would be like: {token: xxx, expires: 1671024457558, ssl: true}
    // Keep in mind that the `expires` field is NOT a normal unix timestamp value.
    // Before converting to any other format, trim the last 3 digits.
    return res.data;
  } catch (error) {
    console.log("error fetching arcgis api token", error);
    return undefined;
  }
}

/**
 * `expiresOn` is a unix timestamp-like value but with extra digits at its tail
 **/
export function isTimestampExpired(expiresOn) {
  return moment().isSameOrAfter(moment(Math.floor(expiresOn / 1000)));
}
