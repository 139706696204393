import {createContext, useState, useEffect, useContext} from "react";
import {getCategoriesFromDb} from "../services/Categories";

const appContext = createContext({});
export const useAppContext = () => useContext(appContext);

const VODAFONE_NZ = {
    lat: -36.7845,
    lng: 174.7546,
};

const AppContextProvider = ({children}) => {
    const [latLng, setLatLng] = useState(VODAFONE_NZ);
    const [allCategories, setAllCategories] = useState([]);
    // pins in a postal area fetched from DB
    const [pinsInPostalArea, setPinsInPostalArea] = useState([]);
    // the markers to be rendered on map in 'step 3', filtered by categories
    const [markersOnMap, setMarkersOnMap] = useState([]);
    // categoryIds vary on user's selection in step 3
    const [categoryIds, setCategoryIds] = useState([]);
    // for future, opt to use globalStep from this context
    const [globalStep, setGlobalStep] = useState(0);
    const [formData, setFormData] = useState({
        address: "",
        postCode: "",
        lat: "",
        lng: "",
        categoryIdToReport: 0,
        email: "",
        phoneNumber: "",
        description: "",
        userMovedPin: false
    });

    // type: {token: string, expires: number}
    const [arcGisToken, setArcGisToken] = useState(null);

    const [outagePolygons, setOutagePolygons] = useState({
        polygons: [], show: false
    });

    const [newSitePolygons, setNewSitePolygons] = useState({
        polygons: [], show: false
    })

    useEffect(() => {
        getCategoriesFromDb().then((categories) => {
            setAllCategories(categories);
            setCategoryIds(categories.map((c) => c.id));
        });
    }, []);

    useEffect(() => {
        const pinsOfCategories = pinsInPostalArea.filter(pin =>
            categoryIds.includes(pin.category_ids?.[0])
        );
        setMarkersOnMap(pinsOfCategories);
    }, [categoryIds, pinsInPostalArea]);

    // put what should be accessed from app context here.
    const contextValues = {
        formData,
        setFormData,
        latLng,
        setLatLng,
        markersOnMap,
        categoryIds,
        setCategoryIds,
        allCategories,
        setAllCategories,
        globalStep,
        setPinsInPostalArea,
        setGlobalStep,
        arcGisToken, setArcGisToken,
        outagePolygons, setOutagePolygons,
        newSitePolygons, setNewSitePolygons
    };

    return (
        // this is the provider providing state
        <appContext.Provider value={contextValues}>{children}</appContext.Provider>
    );
};

export default AppContextProvider;
