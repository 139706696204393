import React, { useState } from "react";
import { Card, Form} from "react-bootstrap";
import { CategoryOptions } from "../CategoryOptions/CategoryOptions";
import styles from "./Steps.module.css";
import { postReport } from "../../services/Markers";
import { useAppContext } from '../../context/appContext'

export const StepTwo = ({ nextStep, prevStep }) => {
  const [error, setError] = useState(false);
  const { formData, setFormData } = useAppContext()

  const submitFormData = (e) => {
    e.preventDefault();
    let categoryId;
    e.target.category.forEach((radio) => {
      if (radio.checked) categoryId = radio.id;
    });
    if (!categoryId) {
      setError(true);
      return;
    }

    const data = {
      ...formData,
      description: e.target.description.value,
      visible: !e.target.visible.checked,
      categoryIdToReport: categoryId,
    };
    setFormData(data);
    postReport(data);
    nextStep();
  };

  return (
    <div id="formStepTwo">
      <Card>
        <Card.Body>
          <h2 className="one-heading-small">Drop the pin and select the network category</h2>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mt-4 mb-3">
              <Form.Label className="one-label-large">Category</Form.Label>
              <CategoryOptions type="radio" showColors={false} />
              {error && (
                <Form.Text className="error">
                  This is a required field
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="one-label-large">Description (optional)</Form.Label>
              <Form.Control
                className={styles.descriptionTextArea}
                name="description"
                id="description"
                defaultValue={formData.description}
                type="text"
                as="textarea"
                rows={5}
              />
            </Form.Group>

            <Form.Group className="mt-4 mb-3">
              <Form.Label className="one-label-large">Visibility</Form.Label>
              <br/>
              <Form.Check className="d-inline-block">
                <Form.Check.Input
                    name="visible"
                    type="checkbox"
                    defaultChecked={false}
                />
                <Form.Check.Label className="one-body-medium">Only allow One NZ to see my pin</Form.Check.Label>
              </Form.Check>
              {error && (
                  <Form.Text className="error">
                    This is a required field
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex flex-row gap-2">
              <button onClick={prevStep} type="button">
                Go back
              </button>
              <button type="submit">
                Next
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
