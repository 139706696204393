import axios from "axios";

export const getCategoriesFromDb = () => {
  return axios(
    "/api/categories?select=id,name,description,is_visible,pin_icons(icon)"
  ).then((response) => {
    return response.data
      .filter((category) => category.is_visible)
      .map((category) => {
        return {
          id: category.id,
          name: category.name,
          description: category.description,
          pinIcon: category.pin_icons.icon,
        };
      });
  });
};
