import styles from "./Header.module.css";
import { Row, Navbar, Nav, Image } from "react-bootstrap";
import OneNzLogo from "../../images/one-nz-logo.svg";
import headerImage from "../../images/hero-section-bg.jpg";
import ChevronRightWhite from "../../images/chevron-right-white.svg";
import {BsChevronRight} from "react-icons/bs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import classNames from "classnames";

const topLinks = [
  {
    title: "Personal",
    href: "https://one.nz",
  },
  {
    title: "Business",
    href: "https://one.nz/business/",
  },
  {
    title: "Why Choose Us",
    href: "#",
    active: true,
  },
  {
    title: "Infrastructure Partners",
    href: "https://one.nz/our-networks/infrastructure-partners/",
  },
];

const links = [
  {
    title: "One Smart Network",
    href: "https://one.nz/our-networks/",
    links: [
      {
        title: "Reliable coverage",
        href: "https://one.nz/network/coverage/",
      },
      {
        title: "Reliable data",
        href: "https://one.nz/network/speed/",
      },
      {
        title: "Reliable connection",
        href: "https://one.nz/network/reliable/",
      },
      { title: "5G", href: "https://one.nz/5g/" },
      { title: "Our guarantee", href: "https://one.nz/guarantee/" },
      { title: "One Pin", href: "https://onepin.one.nz/" },
    ],
  },
  {
    title: "About Us",
    href: "https://one.nz/about/",
    links: [
      {
        title: "Corporate Responsibility",
        href: "https://one.nz/our-company/corporate-responsibility/",
        links: [
          {
            title: "Te Rourou Foundation",
            href: "https://terourou.one.nz/",
          },
          { title: "Recycling electronics", href: "https://one.nz/environment/mobile-recycling/" },
          { title: "Eco Rating", href: "https://one.nz/mobile-phones/eco-rating/" },
          { title: "Trade in your mobile", href: "https://tradein.one.nz/" },
        ]
      },
      {
        title: "Our partnerships",
        href: "https://one.nz/about/sponsorship/",
      }
    ],
  },
];

export const Header = () => {
  const [currentSuperNavItemIndex, setCurrentSuperNavItemIndex] = useState(0);
  const [mobileNavDropdownOpen, setMobileNavDropdownOpen] = useState(false);

  useEffect(() => {
    if (mobileNavDropdownOpen) {
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
      document.body.style.overflowY = "scroll";
    }
  }, [mobileNavDropdownOpen]);

  return (
    <Row className={styles.component}>
      {/* Mini Navbar */}
      <Navbar className={`${styles.topMiniNavbar} d-none d-lg-block`}>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {topLinks.map((link, index) => (
              <Nav.Link
                href={link.href}
                key={index}
                className={link.active ? styles.topMiniNavbarActive : ""}
              >
                {link.title}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Brand Navbar */}
      <Row className={styles.brandNavbarWrapper}>
        <Navbar
          className={[
            styles.brandNavbar,
            "justify-content-between justify-content-lg-start",
          ].join(" ")}
        >
          <Navbar.Brand href="https://one.nz">
            <Image src={OneNzLogo} className={styles.iconHome}/>
          </Navbar.Brand>
          <Nav
            className={[styles.brandNavbarNavItems, "d-none d-lg-flex"].join(
              " "
            )}
          >
            {links.map((superLink, index) => (
              <Nav.Link
                href={superLink.href}
                key={index}
                onMouseEnter={(e) => {
                  setCurrentSuperNavItemIndex(index);
                  if (links[index].links?.length) {
                    const subNavElement =
                      e.currentTarget.parentElement.parentElement
                        .nextElementSibling;
                    subNavElement.style.visibility = "visible";
                  }
                }}
              >
                <span>{superLink.title}</span>
              </Nav.Link>
            ))}
          </Nav>
          <Nav className="justify-content-end d-flex d-lg-none">
            <Navbar.Text className={styles.brandNavbarIcons}>
              <button
                onClick={() => {
                  setMobileNavDropdownOpen((oldValue) => !oldValue);
                }}
              >
                {mobileNavDropdownOpen ? (
                  <FontAwesomeIcon icon={faClose} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </button>
            </Navbar.Text>
          </Nav>
        </Navbar>
        {/* sub links, show on hovering on the super links*/}
        <Navbar
          className={[styles.subNavbarWrapper, "d-none d-lg-block"]}
          onMouseLeave={(e) => {
            e.currentTarget.style.visibility = "hidden";
          }}
        >
          <Navbar.Collapse>
            <Nav>
              {links[currentSuperNavItemIndex]?.links?.map((subLink, index) => (
                <Nav.Link href={subLink.href} key={index}>
                  {subLink.title}
                  {/* subSubNavDropdown */}
                  {subLink.links?.length && (
                    <Nav className={[styles.navDropdown, "flex-column"]}>
                      {subLink.links.map((link, index) => (
                        <Nav.Link href={link.href} key={index}>
                          {link.title}
                        </Nav.Link>
                      ))}
                    </Nav>
                  )}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* nav dropdown, only visible on mobile */}
        <div
          className={[
            styles.mobileNavDropDownWrapper,
            !mobileNavDropdownOpen ? "invisible" : "visible",
            "d-flex d-lg-none",
          ].join(" ")}
        >
          <Accordion flush>
            {links.map((layerOneLink, index) => (
              <Accordion.Item
                eventKey={index}
                key={index}
                className={styles.smLinkGroup}
              >
                <Accordion.Header className={styles.smLinkGroupHeader}>
                  <Nav.Link href={layerOneLink.href}>
                    {layerOneLink.title}
                  </Nav.Link>
                </Accordion.Header>
                {layerOneLink.links?.length && (
                  <Accordion.Body className={styles.smLinkGroupBody}>
                    <Accordion flush>
                      {layerOneLink.links.map((layerTwoLink, index) => (
                        <Accordion.Item
                          eventKey={layerTwoLink.title}
                          key={index}
                          className={styles.smLinkGroup}
                        >
                          <Accordion.Header
                            className={[
                              styles.smLinkGroupHeader,
                              layerTwoLink.links?.length
                                ? ""
                                : styles.chevronRemoved,
                            ]}
                          >
                            <Nav.Link href={layerTwoLink.href}>
                              {layerTwoLink.title}
                            </Nav.Link>
                          </Accordion.Header>
                          {layerTwoLink.links?.length && (
                            <Accordion.Body className={styles.smLinkGroupBody}>
                              {layerTwoLink.links.map(
                                (layerThreeLink, index) => (
                                  <Nav.Link
                                    key={index}
                                    href={layerThreeLink.href}
                                  >
                                    {layerThreeLink.title}
                                  </Nav.Link>
                                )
                              )}
                            </Accordion.Body>
                          )}
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            ))}
          </Accordion>
          <ul>
            {topLinks
              .filter((link) => !link.active)
              .map((link, index) => (
                <a href={link.href} key={index}>
                  <li>
                    <span>{link.title}</span>
                    <span>
                      <img src={ChevronRightWhite} width={24} height={24} alt={`link to ${link.title} page`}/>
                    </span>
                  </li>
                </a>
              ))}
          </ul>
        </div>
      </Row>

      {/* hero section*/}
      <Row className={styles.homeHeaderImageWrapper}>
        <Image src={headerImage}></Image>
        <div className={styles.homeHeaderWrapper}>
          <h1>One Pin to connect New Zealand</h1>
          <p>
            <a
              className={classNames("d-lg-none", styles.heroButton)}
              href="#start-onepin"
            >
              Start
            </a>
          </p>
        </div>
      </Row>

      {/* Post Header Image Navbar */}
      <div className={styles.breadcrumbNav}>
        <div className={styles.breadcrumbsNavWrapper}>
          <div className={classNames(styles.breadcrumbsNavItems, "one-body-medium")}>
            <a href="https://one.nz/mobile/">One Smart Network</a>
            <span className={styles.breadcrumbNavChevron}><BsChevronRight /></span>
            <a href="#">One Pin</a>
          </div>
        </div>
      </div>
    </Row>
  );
};
