import proj4 from "proj4";
import axios from "axios";
import { useAppContext } from "../../context/appContext";
import getToken, { isTimestampExpired } from "./token";
import moment from "moment";

const ArcGisDataProxyUrl =
  "https://australia-southeast1-pin-drop-349003.cloudfunctions.net/arcgis-data-proxy";

export const PolygonType = {
  OUTAGES: "outages",
  NEW_SITES: "new sites",
};

// To enable the date query functions to use context value `token`, we need to create a wrapper hook
export default function useArcGisData() {
  const { arcGisToken, setArcGisToken } = useAppContext();

  // Returns token object or null
  async function getValidTokenString() {
    // Check if arcGisToken exists and hasn't expired, return arcGisToken.token
    if (!!arcGisToken?.expires && !isTimestampExpired(arcGisToken.expires)) {
      return arcGisToken.token;
    }
    // Otherwise, get a new token obj and set the context value
    else {
      const newTokenObj = await getToken();
      if (newTokenObj) {
        setArcGisToken(newTokenObj);
        return newTokenObj.token;
      } else throw new Error("Failed to get an ArcGis token");
    }
  }

  // Whatever the response is outages or new sites,
  // it always returns { type: string, start: string, end: string | null, note: string | null }
  function convertMetaToInfoCard(meta) {
    // Based on the knowledge that outage data will have "outage" field equal to "True"
    if (meta.outage === "True") {
      return {
        type: `Outage - ${meta.type}`,
        start: moment(meta.starttime).format("DD/MM/YYYY h:mm a"),
        end: meta.endtime
          ? moment(meta.endtime).format("DD/MM/YYYY h:mm a")
          : "unknown",
        note: meta.notes,
      };
    } else
      return {
        type:
          `Upgrade` + (meta.rollout_status ? ` - ${meta.rollout_status}` : ""),
      };
  }

  function getRingsFromArcGisResponse(arcGisResponse) {
    const outputRings = [];
    const input_coord_system = "EPSG:3857";
    const output_coord_system = "EPSG:4326";

    arcGisResponse.features.forEach((feature) => {
      const ring = feature?.geometry?.rings?.[0];
      const meta = feature?.attributes;

      // This line filters out some polygon outliers for new sites
      if (meta.geodb_type === null) return;

      if (ring && meta) {
        outputRings.push({
          ring: ring.map((coord) => {
            const convertedInArray = proj4(
              input_coord_system,
              output_coord_system,
              coord
            );
            return { lat: convertedInArray[1], lng: convertedInArray[0] };
          }),
          meta: convertMetaToInfoCard(meta),
        });
      }
    });

    return outputRings;
  }

  async function getPolgyons({ type, lat, lng, distance, where }) {
    let rings = [];

    try {
      // get valid token
      const token = await getValidTokenString();
      const params = { lat, lng, distance, token, where };
      const path = type === PolygonType.OUTAGES ? "/outage" : "/new-sites";

      try {
        const res = await axios.get(`${ArcGisDataProxyUrl}${path}`, { params });
        rings = getRingsFromArcGisResponse(res.data);
      } catch (error) {
        console.log("Error requesting data to proxy", error);
      }
    } catch (error) {
      console.log("Error getting token", error);
    }

    return rings;
  }

  return {
    getPolgyons,
  };
}
