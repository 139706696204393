import React, {Fragment} from "react";
import { useLocation } from "react-router-dom";
import styles from "./Map.module.css";
import { GoogleMap, Marker, useJsApiLoader, Polygon, OverlayView} from "@react-google-maps/api";
import { useAppContext } from "../../context/appContext";
import spotlightMarker from "../../images/spotlight-marker.png";
import useArcGisData, { PolygonType } from "../../services/ArcGis/useArcGisData";

const centerDefault = {
  lat: -36.882161,
  lng: 174.804388,
};

const META_DISPLAY = {
  NONE:"none",
  TYPE_ONLY: "type_only",
  FULL: "full"
}

export const PolygonColor = {
  OUTAGES: "#D31F3F",
  NEW_SITES: "#1B7C53"
}

export const Map = ({ GoogleMapsApiKey, Center, Zoom, mapContainerStyle }) => {
  const { latLng, setLatLng, markersOnMap, allCategories, outagePolygons, setOutagePolygons, newSitePolygons, setNewSitePolygons} = useAppContext();
  const { getPolgyons } = useArcGisData();
  const path = useLocation().pathname;
  const { formData, setFormData } = useAppContext();

  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    if (map && path && path !== "/") {
      map.setZoom(14);
    }

    if (
      map &&
      path &&
      path === "/drop-pins"
    ) {
      map.setZoom(15);
      map.setOptions({
        zoomControl: true,
        scrollwheel: true,
        gestureHandling: "cooperative",
      });
    }

    if( map && path && path === "/show-improvements" && latLng && (outagePolygons.show || newSitePolygons.show)) {
      (async () => {
        if (outagePolygons.show) {
          const polygons = await getPolgyons({...latLng, distance: 1, type: PolygonType.OUTAGES});
          setOutagePolygons({...outagePolygons, polygons});
        }
        if (newSitePolygons.show) {
          const polygons = await getPolgyons({ ...latLng, distance: 1, type: PolygonType.NEW_SITES, where: "id NOT IN ('Planned', 'Completed')"});
          setNewSitePolygons({...newSitePolygons, polygons});
        }
      })();
      map.setZoom(14);
      map.setOptions({
        draggable: true,
        gestureHandling: "cooperative",
      })
    }
  }, [map, path, outagePolygons.show, newSitePolygons.show]);


  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsApiKey,
    library: ["places"],
  });

  const [currentZoom, setCurrentZoom] = React.useState(Zoom);

  const onLoad = React.useCallback(
    function callback(map) {
      new window.google.maps.LatLngBounds(Center ? Center : centerDefault);
      map.setZoom(Zoom);
      setMap(map);
      setCurrentZoom(map.getZoom());
    },
    [Center, Zoom]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const setMarkerLocation = (e) => {
    const location = e.latLng.toJSON();
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location: e.latLng })
      .then((response) => {
        if (response.results[0]) {
          let addressData = {
            address: response.results[0].formatted_address,
            postCode: response.results[0]?.address_components?.find (
              (entry) => entry.types?.[0] === "postal_code"
            )?.long_name || "",
            lat: location.lat,
            lng: location.lng,
            userMovedPin: true,
          }
          setFormData({ ...formData, ...addressData });
        } else {
          console.log("No results found");
        }
      }).catch((e) => console.log("Geocoder failed due to: " + e));

    setLatLng(location);
  };

  const getIcon = (marker) => {
    const catId = marker.category_ids[0];
    const icon = allCategories.find((x) => x.id === catId).pinIcon;
    return `images/${icon}`;
  };

  const loadMarkers = () => {
    return markersOnMap.map(function (marker, index) {
      return (
        <Marker
          key={index}
          position={{ lng: marker.longitude, lat: marker.latitude }}
          icon={getIcon(marker)}
        />
      );
    });
  };

  const loadCurrentMarker = () => {
    return (
      latLng && (
        <Marker
          position={latLng}
          onDragEnd={setMarkerLocation}
          draggable={path === "/drop-pins"}
          icon={spotlightMarker}
        />
      )
    );
  };

  const HintOverLay = ({text}) => (
    <div className={styles.draggableHint}>
      <p>{text}</p>
    </div>
  );

  const handleZoomChanged = () => {
    if(mapRef.current) {
      setCurrentZoom(mapRef.current.state.map.getZoom());
    }
  }

  const mapRef = React.useRef()

  return (
    isLoaded && (
      <GoogleMap
        ref={mapRef}
        className={styles.mapBox}
        center={path === "/" ? Center : latLng}
        zoom={path === "/" ? Zoom : 14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={handleZoomChanged}
        mapContainerStyle={
          mapContainerStyle
            ? mapContainerStyle
            : { width: "400px", height: "700px" }
        }
        clickableIcons={false}
        options={{
          disableDefaultUI: true,
          keyboardShortcuts: false
        }}
      >
        {path === "/show-improvements" && loadMarkers() }
        {path === "/show-improvements" && ( outagePolygons.show || newSitePolygons.show ) && isLoaded && 
          <Fragment>
            <HintOverLay text={"Zoom in to see details of the upgrades or outages"}/>
            { outagePolygons.show && <ArgGisPolygons
              polygons={outagePolygons.polygons}
              metaDisplay={currentZoom > 15 ? META_DISPLAY.FULL : currentZoom <= 13 ? META_DISPLAY.NONE : META_DISPLAY.TYPE_ONLY}
              color={PolygonColor.OUTAGES}
            /> }
            { newSitePolygons.show && <ArgGisPolygons
              polygons={newSitePolygons.polygons}
              metaDisplay={currentZoom > 15 ? META_DISPLAY.FULL : currentZoom <= 13 ? META_DISPLAY.NONE : META_DISPLAY.TYPE_ONLY}
              color={PolygonColor.NEW_SITES}
            /> }
          </Fragment>}
        {path !== "/" && loadCurrentMarker()}
        {path === "/drop-pins" && <HintOverLay text={"Drag the pin to your location"}/>}
      </GoogleMap>
    )
  );
};

function ArgGisPolygons({ polygons, metaDisplay, color }) {
  const options = {
    fillColor: color,
    fillOpacity: 0.6,
    strokeColor: color,
    strokeOpacity: 0.3,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
  }
  return polygons.map((polygon, index) => {
      const {ring, meta} = polygon;
      const len = ring.length;
      let latSum =0, lngSum=0;
      for(let i=0; i<len;i++){
        latSum = latSum+ring[i].lat;
        lngSum = lngSum + ring[i].lng;
      }
      const centroid = {lat: latSum / len, lng:lngSum / len};

      return <Fragment key={index}>
        <Polygon paths={ring} options={options}/>
        { metaDisplay === META_DISPLAY.NONE || <OverlayView position={centroid}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <ul style={{listStylePosition:"inside", listStyleType:"none", color: "white", padding:6, transform: "translate(-50%, -50%)", fontSize: 13, backgroundColor: "#5A6376"}}>
            <li style={{ fontWeight: "bold" }}>{meta.type}</li>
            {metaDisplay === META_DISPLAY.TYPE_ONLY || <Fragment>
              {meta.start && <li>FROM: {meta.start}</li>}
              {meta.end && <li>TO: {meta.end}</li>}
              {meta.note && <li>NOTE: {meta.note}</li>}
            </Fragment>}
          </ul>
        </OverlayView>}
      </Fragment>
    })
}

