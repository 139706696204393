import twitterIcon from "../../images/twitter.png";
import emailIcon from "../../images/email.svg";
import facebookIcon from "../../images/facebook.svg";
import linkIcon from "../../images/clipboard.svg";
import styles from "./Steps.module.css";
import {Overlay} from "react-bootstrap";
import {useRef, useState} from "react";

export const SocialMedia = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);


  const url = window.location.href;
  const email = {
    subject: "Help One NZ fine-tune the network",
    body: `Help @one.nz fine-tune the network. From blackspots to prioritising 5G. You add a pin where it needs a tweak, we prioritise the work based on the number of pins. Simple. %0D%0A %0D%0A ${url}`,
  };
  const twitter = {
    text: `Help @one.nz fine-tune the network. From blackspots to prioritising 5G. You add a pin where it needs a tweak, we prioritise the work based on the number of pins. Simple. ${url}`,
  };

  return (
    <div className="pb-4 d-flex flex-column align-items-start border-top border-2 border-secondary">
      <p className="one-label-large mt-3">
        Share One Pin with friends or family
      </p>
      <div className={styles.socialIconsWrapper}>
        <a
          className="twitter-share-button"
          target="_blank"
          rel="noreferrer"
          href={`https://twitter.com/intent/tweet?text=${twitter.text}`}
          data-size="large"
        >
          <img src={twitterIcon} alt="Share to X" />
        </a>
        <a href={`mailto:?subject=${email.subject}&body=${email.body}`}>
          <img src={emailIcon} alt="Email"/>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore`}
        >
          <img src={facebookIcon} alt="Share Facebook"/>
        </a>
        <img
          ref={target}
          src={linkIcon}
          role="button"
          alt="Copy Link"
          onClick={() => {
            navigator.clipboard.writeText(url);
            setShow(true);
            const timer = setTimeout(()=>{
              setShow(false)
              clearTimeout(timer);
            }, 2000);
          }}
        />
        <Overlay target={target.current} show={show} placement="right">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                  {...props}
                  style={{
                    position: 'absolute',
                    marginLeft:10,
                    backgroundColor: 'black',
                    padding: '3px 10px',
                    color: 'white',
                    borderRadius: 6,
                    ...props.style,
                    fontSize: 14
                  }}
              >
                Page link copied to clipboard!
              </div>
          )}
        </Overlay>
      </div>
    </div>
  );
};
