import React, {useEffect} from "react";
import {Form, Card} from "react-bootstrap";
import {CategoryOptions} from "../CategoryOptions/CategoryOptions";
import {useAppContext} from "../../context/appContext";
import {SocialMedia} from "./SocialMedia";
import {getMarkersByPostCode} from '../../services/Markers'
import {ArcGisOptions} from "../ArcGisOptions/ArcGisOptions";

export const StepThree = () => {
    const {setCategoryIds, globalStep, setPinsInPostalArea, formData, outagePolygons, setOutagePolygons, newSitePolygons, setNewSitePolygons} = useAppContext();

    useEffect(() => {
        const currentReportAsAPin = {
            category_ids: [parseInt(formData.categoryIdToReport)],
            latitude: formData.lat,
            longitude: formData.lng,
            postal_code: formData.postCode,
        };
        if (formData.postCode) {
            if (globalStep === 3) {
                getMarkersByPostCode(formData.postCode).then((pins) => {
                    setPinsInPostalArea([...pins, currentReportAsAPin]);
                })
            }
        } else {
            setPinsInPostalArea([currentReportAsAPin]);
        }

    }, [])

    const updateCategoryIdsUponChecking = (e) => {
        let ids = [];
        e.target.form.categories.forEach((node) => {
            if (node.checked) ids.push(parseInt(node.id));
        });
        setCategoryIds(ids);
    };

    const updateArcGisFeaturesOnMap = (e) => {
        const target = e.target.form.featureType; 
        target.forEach(node => {
            if (node.id === 'outage') {
                console.log("checking outage", node.checked);
                setOutagePolygons({ ...outagePolygons, show: node.checked });
            }
            if (node.id === 'new-sites') {
                console.log("checking new sites", node.checked);
                setNewSitePolygons({ ...newSitePolygons, show: node.checked });
            }
        });
    }

    return (
        <div id="formStepThree">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div>
                        <h2 className="one-heading-small">Thanks for dropping a pin!</h2>
                        <p className="one-body-medium">
                            Your pin has now been logged with our Network team and they will use it to help inform the prioritisation plan for upgrades in that region. Thanks for helping us create a better network for Aotearoa!
                        </p>
                        <p className="one-body-medium">
                            Want to know what&apos;s already in the works? Use the interactive map to track recently completed and planned upgrades.
                        </p>
                        <p className="one-body-medium">If you&apos;d like to know more about what we&apos;re doing with our network, find out more&nbsp;
                            <a
                                className="one-link-large"
                                href="https://one.nz/our-networks/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here
                            </a>.
                        </p>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="one-label-large">
                                    View upgrades
                                </Form.Label>
                                <ArcGisOptions
                                    handleCheckBoxCheck={updateArcGisFeaturesOnMap}
                                    items={[
                                      { id: 'new-sites', title: "New sites / site upgrades" }
                                    ]}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="one-label-large">
                                    View where other pins have dropped
                                </Form.Label>
                                <CategoryOptions
                                    type="checkbox"
                                    showColors={true}
                                    isChecked="checked"
                                    handleCheckBoxCheck={updateCategoryIdsUponChecking}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="one-label-large">
                                    View outages
                                </Form.Label>
                                <ArcGisOptions
                                    handleCheckBoxCheck={updateArcGisFeaturesOnMap}
                                    items={[
                                      { id: 'outage', title: "Network outages" }
                                    ]}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <SocialMedia/>
                </Card.Body>
            </Card>
        </div>
    );
};
