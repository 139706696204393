import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { useAppContext } from '../../context/appContext'

export const Form = ({ startingStep }) => {
  const navigate = useNavigate();
  let [step, setStep] = useState(startingStep ? startingStep : 1);
  const { setGlobalStep } = useAppContext();

  const nextStep = () => {
    let nextStep = step + 1;
    switch (nextStep) {
      case 3:
        navigate("/show-improvements");
        break;
      case 2:
        navigate("/drop-pins");
        break;
      case 1:
      default:
        navigate("/");
        break;
    }
    setGlobalStep(nextStep);
    setStep(nextStep);
  };

  const prevStep = () => {
    let prevStep = step - 1;
    switch (prevStep) {
      case 3:
        navigate("/show-improvements");
        break;
      case 2:
        navigate("/drop-pins");
        break;
      case 1:
      default:
        navigate("/");
        break;
    }
    setStep(prevStep);
  };

  switch (startingStep ? startingStep : step) {
    case 3:
      return <StepThree />;
    case 2:
      return <StepTwo {...{ nextStep, prevStep }} />;
    case 1:
    default:
      return <StepOne {...{ nextStep, prevStep }} />;
  }
};
