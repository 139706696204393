import { useLocation } from "react-router-dom";
import styles from "./Steps.module.css";
import { FiMapPin, FiMap, FiShare2 } from "react-icons/fi";
import { Col } from "react-bootstrap";
import classNames from "classnames";

const stepContent = [
  {
    step: 1,
    icon: FiMapPin,
    copy: "Enter the address",
  },
  {
    step: 2,
    icon: FiMap,
    copy: "Drop a pin",
  },
  {
    step: 3,
    icon: FiShare2,
    copy: "Share One Pin",
  },
];

function getCurrentIndex(path) {
  return path === "/"
    ? 0
    : path === "/drop-pins"
    ? 1
    : path === "/show-improvements"
    ? 2
    : -1;
}

export const Steps = () => {
  const location = useLocation();
  const currentIndex = getCurrentIndex(location.pathname);

  return (
    <div
      className={classNames("steps-wrapper", styles.stepsWrapper)}
      id="start-onepin"
    >
      <h3 className="one-display-small d-block d-lg-none">Steps</h3>
      <div className={styles.tilesWrapper}>
        {stepContent.map((step, index) => (
          <Step content={step} key={index} isActive={currentIndex === index} />
        ))}
      </div>
      <p
        className={classNames(
          styles.smCurrentTitle,
          currentIndex === 0
            ? "text-start"
            : currentIndex === 1
            ? "text-center"
            : "text-end",
          "d-block d-lg-none mt-4",
          "one-heading-large"
        )}
      >
        {stepContent[currentIndex].copy}
      </p>
    </div>
  );
};

const Step = ({ content, isActive }) => {
  return (
    <Col>
      <div
        className={[
          styles.stepContainer,
          isActive ? styles.topBorderHighlighted : "",
          "py-4 d-none d-lg-block",
        ].join(" ")}
      >
        <div className="d-flex flex-row align-items-start">
          <content.icon
            className={classNames(isActive ? styles.currentIcon : styles.icon)}
          />
          <div className={classNames(styles.text, "d-flex flex-column")}>
            <p className="mb-0 one-label-large">step {content.step}</p>
            <h3
              className={classNames(
                "one-heading-large",
                isActive ? styles.currentTitle : styles.title
              )}
            >
              {content.copy}
            </h3>
          </div>
        </div>
      </div>

      {/* step indicator on smaller screens */}
      <div className="d-lg-none col-12">
        <div
          className={[
            styles.bottomBordered,
            isActive ? styles.bottomBorderHighlighted : "",
            "py-2 py-lg-4 d-flex align-items-center",
          ].join(" ")}
        >
          <h3
            className={`${
              isActive ? styles.currentTitle : styles.title
            } mt-3 mx-2`}
          >
            {content.step}
          </h3>
        </div>
      </div>
    </Col>
  );
};
